import React from "react"
import { graphql } from "gatsby"
import LayoutSub from "../components/layout-sub"
import SEO from "../components/seo";
import WidgetContactEmail from "../components/widget-contact-email";
import WidgetContactPhone from "../components/widget-contact-phone";

export default ({ data }) => {
  const post = data.markdownRemark
  return (
      <LayoutSub title={post.frontmatter.title}>

      <SEO
      title={post.frontmatter.title}
      description={post.frontmatter.seo.description}
      keywords={post.frontmatter.seo.keywords.toString().split(',')}
      />

      <article id="main-article" className="container flex flex-wrap items-start lg:flex-no-wrap mx-auto p-4 md:p-8 xl:p-16">
      <div role="main" className="article w-full lg:w-2/3 lg:pr-20 xl:pr-40">
        <div className="article" dangerouslySetInnerHTML={createMarkup(post.html)} />
      </div>

      <aside id="sidebar" className="w-full bg-grey-lightest px-8 pt-8 lg:pt-16 lg:-mt-16 xl:p-16 xxl:pt-16 xxl:p-8 lg:w-1/3">
        <section className="bg-white mb-8 p-6">
          <h5 className="text-base mb-2 hyphens">Hur kan vi hjälpa dig?</h5>
          <p className="mb-4 text-sm">Kontakta oss så berättar vi gärna mer om hur vi kan hjälpa er.</p>
        </section>

        <section className="xxl:flex">
          <WidgetContactEmail />
          <WidgetContactPhone />
        </section>
      </aside>
      </article>
      </LayoutSub>

  )
}

function createMarkup(html) {
  return {__html: html};
}

export const query = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        seo {
          description
          keywords
          title
        }
      }
    }
  }
`